import React, { useReducer, useState, useEffect } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import axios from 'axios';
import '../assets/styles/pages/quote/step-six.scss';
import Layout from '../components/layout';
import SEO from '../components/seo';
import DingGoFleetPortal from '../images/DingGo-Digital-Fleet-Portal.png'
import DingGoFleetToolbox from '../images/DingGo-Digital-Fleet-Toolbox-Icon.png'

/* global window */
const Demo = () => {
  const data = useStaticQuery(graphql`
    query {
      general {
        email
        country_code
      }
      contactus {
        customertype {
          customerType_id
          description
        },
        recaptcha {
          siteKey
        }
        seo {
          title
          canonical
          description
          og {
            description
            image
            title
            type
            url
          }
        }
      }
    }
  `);

  const { seo, customertype, recaptcha } = data.contactus;
  const { email } = data.general;
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [contactDetails, setContactDetails] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: '',
      email: '',
      phone: '',
      organisation: '',
    }
  );
  const [contactDetailsValid, setContactDetailsValid] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: true,
      email: true,
      phone: true,
      organisation: true,
    }
  );

  const addScript = (url) => {
    const script = window.document.createElement('script');
    script.src = url;
    script.async = true;
    window.document.body.appendChild(script);
  };

  if (typeof window !== 'undefined') {
    window.ReCaptchaCallbackV3 = () => {
      grecaptcha.ready(() => {
        setCaptcha(grecaptcha);
      });
    };

    if (typeof window.grecaptcha !== 'object') {
      addScript(`https://www.google.com/recaptcha/api.js?onload=ReCaptchaCallbackV3&render=${recaptcha.siteKey}`);
    }
  }

  useEffect(() => {
    // If you want to implement componentWillUnmount,
    // return a function from here, and React will call
    // it prior to unmounting.
    return () => {
      if (typeof window.grecaptcha === 'object') {
        window.grecaptcha = undefined;
      }
    }
  }, []);

  const validateForm = (name, value) => {
    const re = /\S+@\S+\.\S+/;

    if (name === 'name') {
      if (value.length === 0) {
        setContactDetailsValid({ 'name': false });
      } else {
        setContactDetailsValid({ 'name': true });
      }
    }
    if (name === 'email') {
      if (value.length === 0 || !re.test(value)) {
        setContactDetailsValid({ 'email': false });
      } else {
        setContactDetailsValid({ 'email': true });
      }
    }
    if (name === 'phone') {
      if (value.length === 0) {
        setContactDetailsValid({ 'phone': false });
      } else {
        setContactDetailsValid({ 'phone': true });
      }
    }
    if (name === 'organisation') {
      if (value.length === 0) {
        setContactDetailsValid({ 'organisation': false });
      } else {
        setContactDetailsValid({ 'organisation': true });
      }
    }
  };

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    }

    setContactDetails({ [name]: value });
    validateForm(name, value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setSuccessMessage(false);
    setErrorMessage(false);

    if (captcha !== null) {
      grecaptcha.execute(recaptcha.siteKey, { action: 'demo' }).then((token) => {
        contactDetails.token = token;
        axios.post(`${process.env.GATSBY_PORT}endpoints/demo`,
          contactDetails,
        ).then(res => {
          const { data } = res;
          if (data.success) {
            setSuccessMessage(true);
            setLoading(false);
          } else {
            setErrorMessage(true);
            setLoading(false);
          }
        }).catch(error => {
          console.warn(error);
          setErrorMessage(true);
          setLoading(false);
        });
      });
    } else {
      console.warn('Google recaptcha failed to load');
      setErrorMessage(true);
      setLoading(false);
    }
  };

  const hasContactDetails = () => {
    let valid = true;
    const re = /\S+@\S+\.\S+/;

    if (contactDetails.name.length === 0) {
      valid = false;
    }
    if (contactDetails.email.length === 0) {
      valid = false;
    }
    if (!re.test(contactDetails.email)) {
      valid = false;
    }
    if (contactDetails.phone.length === 0) {
      valid = false;
    }
    if (contactDetails.organisation.length === 0) {
      valid = false;
    }

    return valid;
  }

  return (
    <Layout hideHeaderFooter={true}>
      <SEO
        title={seo.title}
        description={seo.title}
        canonical={seo.canonical}
        stucturedData=""
        ogTitle={seo.og.title}
        ogDescription={seo.og.description}
        ogImage={seo.og.image}
        ogType={seo.og.type}
        ogUrl={seo.og.url}
      />
      <div className="quote-wrapper text-center no-min-height">
        <div className="container xs">
          <div className="fleet-section-title">See how we can help you today</div>
          {data.general.country_code === 'au' &&
            <p className="intro">
              DingGo Fleet is an innovative crash management platform that will take care of all your crash management
              needs whilst helping save your business time, administration and costs. We would be happy to show you how
              DingGo Fleet can help your business today.
            </p>
          }
          {data.general.country_code === 'nz' &&
            <p className="intro">
              Crash Management powered by DingGo is an
              innovative platform that will take care of all
              your crash management needs whilst helping
              save your business time, administration and
              costs. We would be happy to show you how
              the power of DingGo can help your business
              today.
            </p>
          }
          <form onSubmit={(e) => { handleSubmit(e); }}>
            <input type="hidden" name="recaptcha_key" value={recaptcha.siteKey} />
            <div className={`form-group ${contactDetailsValid.name ? 'input-valid' : 'input-error'}`}>
              <label>Your name</label>
              <input type="text" className="form-control" id="name" name="name" onChange={(e) => { handleInputChange(e); }} value={contactDetails.name} />
            </div>
            <div className={`form-group ${contactDetailsValid.email ? 'input-valid' : 'input-error'}`}>
              <label>Email address</label>
              <input type="email" className="form-control" id="email" name="email" onChange={(e) => { handleInputChange(e); }} value={contactDetails.email} />
            </div>
            <div className={`form-group ${contactDetailsValid.phone ? 'input-valid' : 'input-error'}`}>
              <label>Phone</label>
              <input type="text" className="form-control" id="phone" name="phone" onChange={(e) => { handleInputChange(e); }} value={contactDetails.phone} />
            </div>
            <div className={`form-group ${contactDetailsValid.organisation ? 'input-valid' : 'input-error'}`}>
              <label>Organisation</label>
              <input type="text" className="form-control" id="organisation" name="organisation" onChange={(e) => { handleInputChange(e); }} value={contactDetails.organisation} />
            </div>
            {errorMessage && (
              <p className="error">
                Hi Sorry we are experiencing a problem submitting your request. Can you please message us on our online chat or email us your issue at <a href={`mailto:${email}`}>{email}</a> and we will look to resolve your case asap. Thank you!
              </p>
            )}
            {successMessage && (
              <p className="success">
                Thanks for your enquiry, we will be in contact soon
              </p>
            )}
            <div className="form-btn-wrapper">
              <button className={`btn ${hasContactDetails() ? '' : 'disable'}`} type="submit">
                {loading && (
                  <div className="lds-ellipsis">
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                )}
                {!loading && 'Request Demo'}
              </button>
            </div>
          </form>
        </div>
      </div>
      <section className="py-7">
        <div className="container text-center">
          <div className="row">
            <div className="col-12">
              <div className="fleet-section-header">
                <div className="fleet-section-title">Our platform, tailored to your needs</div>
                <div className="fleet-section-description">
                  Using the latest technology to build tailor made solutions
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <img src={DingGoFleetPortal} />
            </div>
          </div>
          <div className="row m40 mt20">
            <div className="col-12">
              <div className="fop-co text-center">
                <img src={DingGoFleetToolbox} className="flp-toolboximg" />
                  <div className="ah">
                    Seamless access for all employees<br />
                    Simple mobile friendly submission process<br />
                    Central management through custom dashboard
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Demo;
